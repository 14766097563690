const CustomBadge = ({ text, variant, fontSize = 13 }) => {
  const colors = {
    primary: {
      color: "rgb(0, 108, 156)",
      background: "rgba(0, 184, 217, 0.16)",
    },
    gray: {
      color: "rgb(99, 115, 129)",
      background: "rgba(145, 158, 171, 0.16)",
    },
    error: {
      color: "rgb(183, 29, 24)",
      background: "rgba(255, 86, 48, 0.16)",
    },
    success: {
      color: "rgb(17, 141, 87)",
      background: "rgba(34, 197, 94, 0.16)",
    },
    warning: {
      color: "rgb(183, 110, 0)",
      background: "rgba(255, 171, 0, 0.16)",
    },
  };

  return (
    <div
      style={{
        backgroundColor: `${colors[variant]?.background}`,
        color: `${colors[variant]?.color}`,
        borderRadius: "16px",
        padding: "0 8px",
      }}
    >
      <span style={{ fontSize }}> {text}</span>
    </div>
  );
};

export default CustomBadge;
