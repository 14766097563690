import { useEffect, useState } from "react";
import { requestUpdatePermission } from "./clientPermissions.utils";
import * as services from "./clientPermissions.service";

const useClientPermissions = () => {
  const [data, setData] = useState([]);

  const [selectedClient, setSelectedClient] = useState(null);

  useEffect(() => {
    loadData().then(([s, r]) => {
      if (s) {
        setData(r);
      }
    });
  }, []);

  const loadData = async () => {
    let rst = null;
    const [permissions, clients] = await Promise.all([
      services.getPermissions(),
      services.getClients(),
    ]);

    const _clients = clients[1]["records"];

    if (permissions[0] && clients[0]) {
      const permissionBase = permissions[1];
      const currentClient = _clients[0];

      const clientPermissionsRst = await services.getPermissionByClient(
        currentClient._id,
      );

      const clientPermissionsValidation =
        typeof clientPermissionsRst[1] === "string"
          ? []
          : clientPermissionsRst[1];

      const clientPermissions = cleanClientPermissions(
        clientPermissionsValidation,
      ).map((item) => item.permissionId);

      let matchedPermissions = permissionBase.map((permission) => {
        let p = { ...permission };
        p.checked = clientPermissions.includes(permission._id);
        return p;
      });

      rst = _clients.map((client) => {
        if (client._id === _clients[0]._id) {
          client.permissions = updateParentStatus(matchedPermissions);
        } else {
          client.permissions = permissionBase;
        }

        return client;
      });
      setSelectedClient(_clients[0]);

      return [true, rst];
    }

    return [false, rst];
  };

  const cleanClientPermissions = (permissions) => {
    return permissions?.filter((item) => item.permissionId) || [];
  };

  const updateParentStatus = (permissions) => {
    return permissions.map((p) => {
      let parentCode = p.code;
      let qtyElement = permissions.filter(
        (pb) => pb.code.startsWith(parentCode + "-") && pb,
      ).length;
      let qtyElementChecked = permissions.filter(
        (pe) => pe.code.startsWith(parentCode + "-") && pe.checked,
      ).length;
      if (
        p.code === parentCode &&
        qtyElementChecked > 0 &&
        qtyElementChecked < qtyElement
      ) {
        p.checked = null;
      } else if (
        p.code === parentCode &&
        qtyElementChecked === qtyElement &&
        qtyElement > 0
      ) {
        p.checked = true;
      } else if (
        p.code === parentCode &&
        qtyElementChecked === 0 &&
        qtyElement > 0
      ) {
        p.checked = false;
      }
      return p;
    });
  };

  const getPermissionByClient = async (clientId) => {
    let clientPermissions = data;

    let currentClient = null;
    const clientPermissionsRst = await services.getPermissionByClient(clientId);
    const assignedPermissions = cleanClientPermissions(clientPermissionsRst[1]);
    const newClientPermissions = clientPermissions.map((client) => {
      if (client._id === clientId) {
        currentClient = client;
        client.permissions = updateParentStatus(
          client.permissions.map((permission) => {
            permission.checked = (assignedPermissions || []).find(
              (item) => item.permissionId === permission._id,
            )
              ? true
              : false;
            return permission;
          }),
        );
      }
      return client;
    });
    setSelectedClient(currentClient);
    setData(newClientPermissions);
  };

  const setUpdatePermissionByClient = async (permissionId, checked) => {
    let clientPermissions = data;
    const currentClient = selectedClient;
    const permissionBase = data.find(
      (item) => item._id === currentClient._id,
    ).permissions;
    const permissionObj = permissionBase.find(
      (item) => item._id === permissionId,
    );

    const request = requestUpdatePermission(currentClient._id, permissionObj);
    console.log("request", request);
    let rst = null;
    if (checked) {
      rst = await services.updatePermissionByClient(request);
    } else {
      rst = await services.removePermissionByClient(
        currentClient._id,
        permissionObj._id,
      );
    }

    const prevStateCheck = permissionBase.find(
      (item) => item._id === permissionId,
    ).checked;
    console.log("rst", rst);
    if (rst[0]) {
      const parentCode =
        permissionBase.find((p) => p._id === permissionId).code + "-";

      const newClientPermissions = clientPermissions.map((client) => {
        if (client._id === currentClient._id) {
          client.permissions = updateParentStatus(
            client.permissions.map((p) => {
              if (p._id === permissionId || p.code.startsWith(parentCode)) {
                p.checked = checked;
              }
              return p;
            }),
          );
        }
        return client;
      });
      setData(newClientPermissions);
      return true;
    }
    return prevStateCheck;
  };

  return {
    data,
    setUpdatePermissionByClient,
    getPermissionByClient,
  };
};

export default useClientPermissions;
