import { useEffect, useState } from "react";

export const useFormFilterAirCallAndRingCentral = (setFilters, filters) => {
  const [filterCopy, setFilterCopy] = useState({ ...filters });
  const [dateFrom, setDateFrom] = useState(filters.dateFrom ?? null);
  const [dateTo, setDateTo] = useState(filters.dateTo ?? null);
  const initialState = {
    phoneNumber: "",
    dateFrom: null,
    dateTo: null,
  };

  useEffect(() => {
    setFilterCopy(filters);
    setDateFrom(filters.dateFrom ?? null);
    setDateTo(filters.dateTo ?? null);
  }, [filters]);

  const updateFilters = () => {
    setFilters((prev) => ({
      ...prev,
      phoneNumber: filterCopy.phoneNumber,
      dateFrom,
      dateTo,
    }));
  };

  const resetFilters = () => {
    setDateFrom(null);
    setDateTo(null);
    setFilters(initialState);
    setFilterCopy(initialState);
  };

  const handleChangeFilters = (e) => {
    setFilterCopy({ ...filterCopy, [e.target.name]: e.target.value });
  };

  return {
    filterCopy,
    setFilterCopy,
    updateFilters,
    setDateFrom,
    setDateTo,
    dateFrom,
    dateTo,
    handleChangeFilters,
    resetFilters,
  };
};
