import React from "react";
import withSelectedPractices from "../../HOCS/withSelectedPractices/withSelectedPractices";
import { useRingCentralSms } from "./useRingCentralSms";
import { TemplateWrapped } from "../../Layout";
import CustomTable from "../../CustomTable";
import {
  WrappedFilterPractices,
  WrapperBreadcrumbsFilters,
} from "../AllTransactions/components/styles/filter.styles";
import { HeaderFilterButton } from "../../RemarkTemplate/Body";
import { withoutEmptyKeys } from "../../../util/object";
import FormFilterAirCallAndRingCentral from "../FormFilterAirCallAndRingCentral/FormFilterAirCallAndRingCentral";

const RingCentralSms = () => {
  const { columns, getData, filters, setFilters, ref } = useRingCentralSms();
  return (
    <TemplateWrapped
      title="2FA SMS | Ring Central"
      headerButtons={
        <>
          <div className="d-flex">
            <HeaderFilterButton
              active={
                filters && Object.keys(withoutEmptyKeys(filters)).length > 0
              }
              render={FormFilterAirCallAndRingCentral}
              componentProps={{
                initialData: filters,
                setFilters,
              }}
            />
          </div>
        </>
      }
    >
      <CustomTable
        tableReference={ref}
        search={false}
        rowStyle={(rowData) => {
          return {
            backgroundColor: rowData.audited ? "#e8f9ff7a" : "#fff",
          };
        }}
        columns={columns}
        data={getData}
        pageSize={25}
        components={{
          ...CustomTable.Components,
          Pagination: (props) => (
            <CustomTable.Components.Pagination options={[25, 50]} {...props} />
          ),
          Toolbar: (_props) => (
            <WrapperBreadcrumbsFilters>
              <WrappedFilterPractices></WrappedFilterPractices>
            </WrapperBreadcrumbsFilters>
          ),
        }}
        options={{ draggable: false }}
      />
    </TemplateWrapped>
  );
};

export default withSelectedPractices(RingCentralSms);
