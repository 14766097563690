import React from "react";
import withSelectedPractices from "../../HOCS/withSelectedPractices/withSelectedPractices";
import { TemplateWrapped } from "../../Layout";
import CustomTable from "../../CustomTable";
import {
  WrappedFilterPractices,
  WrapperBreadcrumbsFilters,
} from "../AllTransactions/components/styles/filter.styles";
import { useAirCallSms } from "./useAirCallSms";
import { withoutEmptyKeys } from "../../../util/object";
import { HeaderFilterButton } from "../../RemarkTemplate/Body";
import FormFilterAirCallAndRingCentral from "../FormFilterAirCallAndRingCentral/FormFilterAirCallAndRingCentral";

const AirCallSms = ({
  actions = {
    list_transaction: true,
    audit_transaction: true,
    upload_elg: true,
    unaudit: false,
    updateElegibilityStatus: false,
  },
  permissions,
  ...props
}) => {
  const { columns, getData, filters, setFilters, ref } = useAirCallSms();

  return (
    <TemplateWrapped
      title="2FA SMS | Air Call"
      headerButtons={
        <>
          <div className="d-flex">
            <HeaderFilterButton
              active={
                filters && Object.keys(withoutEmptyKeys(filters)).length > 0
              }
              render={FormFilterAirCallAndRingCentral}
              componentProps={{
                initialData: filters,
                setFilters,
              }}
            />
          </div>
        </>
      }
    >
      <CustomTable
        tableReference={ref}
        asyncDataSource={getData}
        search={false}
        rowStyle={(rowData) => {
          return {
            backgroundColor: rowData.audited ? "#e8f9ff7a" : "#fff",
          };
        }}
        columns={columns}
        pageSize={25}
        components={{
          ...CustomTable.Components,
          Pagination: (props) => (
            <CustomTable.Components.Pagination options={[25, 50]} {...props} />
          ),
          Toolbar: (_props) => (
            <WrapperBreadcrumbsFilters>
              <WrappedFilterPractices></WrappedFilterPractices>
            </WrapperBreadcrumbsFilters>
          ),
        }}
        options={{ draggable: false }}
      />
    </TemplateWrapped>
  );
};

export default withSelectedPractices(AirCallSms);
