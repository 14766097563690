import React from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import CustomDatePicker from "../../CustomDatePicker";
import moment from "moment/moment";
import { useFormFilterAirCallAndRingCentral } from "./hooks/useFormFilterAirCallAndRingCentral";

const FormFilterAirCallAndRingCentral = (props) => {
  const { initialData, setFilters } = props;

  const {
    filterCopy,
    dateFrom,
    setDateFrom,
    dateTo,
    setDateTo,
    updateFilters,
    handleChangeFilters,
    resetFilters,
  } = useFormFilterAirCallAndRingCentral(setFilters, initialData);

  return (
    <Form
      style={{ width: 280 }}
      onSubmit={(e) => {
        e.preventDefault();
        updateFilters();
      }}
    >
      <Row className="m-0">
        <Col sm={12} md={12} lg={12} xl={12} className="p-0">
          <Form.Group className="m-2">
            <Form.Label>Phone Number</Form.Label>
            <Form.Control
              name="phoneNumber"
              placeholder="write a phone number"
              value={filterCopy.phoneNumber}
              onChange={handleChangeFilters}
            />
          </Form.Group>
          <Form.Group className="m-2">
            <Form.Label>Date From</Form.Label>
            <CustomDatePicker
              value={dateFrom ? moment(dateFrom).toDate() : dateFrom}
              name="dateTo"
              onChange={(date) => setDateFrom(date)}
            />
          </Form.Group>
          <Form.Group className="m-2">
            <Form.Label>Date To</Form.Label>
            <CustomDatePicker
              value={dateTo}
              name="dateTo"
              onChange={(date) => setDateTo(date)}
            />
          </Form.Group>
        </Col>
      </Row>
      <div>
        <Row className="mt-2 mb-2" style={{ justifyContent: "center" }}>
          <Col sm={6} md={6} lg={5} xl={5}>
            <Button className="btn-block" variant="primary" type="submit">
              Apply filters
            </Button>
          </Col>
          <Col sm={6} md={6} lg={5} xl={5}>
            <Button
              className="btn-block"
              variant="warning"
              type="button"
              onClick={() => {
                resetFilters();
                //updateFilters();
              }}
            >
              Reset
            </Button>
          </Col>
        </Row>
      </div>
    </Form>
  );
};

export default FormFilterAirCallAndRingCentral;
