import React, { useEffect, useRef } from "react";
import { Route, Routes as V6Switch } from "react-router";
import { useLocation } from "react-router-dom";
import IvMissingCode from "../components/iv_missingCode";
import Main from "../components/Main";
import MissingCode from "../components/pages/MissingCode";
import ClientsForm from "../components/pages/Clients/ClientsForm";
import Clients from "../components/pages/Clients/index";
import AllTransactions from "../components/pages/AllTransactions";
import Authorize from "../components/pages/Authorize";
import Bookmarks from "../components/pages/Bookmarks";
import Connections from "../components/pages/Connections";
import Dashboard from "../components/pages/Dashboard";
import DataQuality from "../components/pages/DataQuality";
import Insuranceverification from "../components/pages/InsuranceVerification";
import Login from "../components/pages/Login";
import LoginHistory from "../components/pages/LoginHistory";
import PasswordReset from "../components/pages/PasswordReset";
import Profile from "../components/pages/Profile";
import ResetPasswordRequest from "../components/pages/ResetPasswordRequest";
import TemplateList from "../components/pages/TemplateList";
import FileConverter from "../components/pages/Tools/FileConverter";
import UserForm from "../components/pages/Users/UserForm";
import Users from "../components/pages/Users/usersGrid";
import TemplateForm from "../components/Template/Form";
import { PrivateRoute } from "./PrivateRoute";
import Billing from "../components/pages/Billing";
import AirCall from "../components/pages/AirCall";
import AirCallDetail from "../components/pages/AirCall/components/AirCallDetail";
import ExtractionSchedule from "../components/pages/ExtractionSchedule";
import TransactionDetail from "../components/pages/TransactionDetail";
import UserRequest from "../components/pages/UserRequest";
import UserRequestDetail from "../components/pages/UserRequestDetail";
import FeeSchedule from "../components/pages/FeeSchedule";
import UpdateRecords from "../components/pages/FeeSchedule/components/UpdateRecords";
import TransactionPlan from "../components/pages/TransactionPlan";
import TransactionPlanDetail from "../components/pages/TransactionPlanDetail";
import DentalMationClients from "../components/pages/DentalMationClients";
import ExtractionLog from "../components/pages/ExtractionLog";
import CredentialsList from "../components/pages/CredentialsList";
import CredentialWrapped from "../components/Credential/CredentialWrapped";
import CredentialEdit from "../components/Credential/CredentialEdit";
import ClientNS from "../components/pages/ClientNS";
import DentalMationProduct from "../components/pages/DentalMationClients/components/DentailMationProduct";
import ClientPermissions from "../components/pages/ClientPermissions/index";
import Tree from "../components/pages/Tree/index";
import Permissions from "../components/pages/Permissions";
import Claims from "../components/pages/Claims";
import AuditTrait from "../components/pages/AuditTrait";
import SharedElements from "../components/pages/SharedElements";
import ShareBookmarks from "../components/pages/SharedElements/components/ShareBookmarks";
import AzureFiles from "../components/pages/AzureFiles";
import Report from "../components/pages/AzureFiles/components/Report";
import ClientPractice from "../components/pages/ClientPractice";
import SftpFiles from "../components/pages/SftpFiles";
import TransactionIssueDetail from "../components/pages/TransactionDetail/components/TransactionIssueDetail";
import SftpFilesReport from "../components/pages/SftpFiles/SftpFilesReport";
import TransactionForm from "../components/pages/TransactionForm";
import FlagColor from "../components/pages/FlagColor";
import SummaryReport from "../components/pages/SummaryReport";
import ReCallTransactions from "../components/pages/ReCallTransactions";
import ColumnsConfiguration from "../components/pages/ColumnsConfiguration";
import { CallCenter } from "../components/pages";
import Intelepeer from "../components/pages/Intelepeer";
import ClientCostReport from "../components/pages/ClientCostReport";
import RingCentralSms from "../components/pages/RingCentralSms";
import AirCallSms from "../components/pages/AirCallSms";

export const defaultPathsByPermissions = {
  home: "/home",
  data_quality: "/dataquality",
  missing_code: "/iv_missingcode",
  template: "/template",
  real_time: "/insuranceverification",
  user: "/users",
  all_transaction: "/allTransaction" /* TODO {frank} */,
};

let breadCumbs = {};

export function getBreadCumb(path) {
  if (!breadCumbs[path]) return [];

  const { displayName, parentPath } = breadCumbs[path];
  let result = [];

  if (parentPath) {
    result = getBreadCumb(parentPath);
  }

  return [
    ...result,
    {
      displayName,
      path,
    },
  ];
}

function BreadCumbSwitch({ children }) {
  breadCumbs = children.reduce(
    (a, { props: { displayName, path, parentPath } }) => {
      if (displayName) {
        a[path] = {
          displayName,
          parentPath,
        };
      }
      return a;
    },
    {},
  );
  return <V6Switch>{children}</V6Switch>;
}

const usePrevLocation = (location) => {
  const prevLocRef = useRef(location);

  useEffect(() => {
    prevLocRef.current = location;
  }, [location]);

  return prevLocRef.current;
};

const Routes = () => {
  const location = useLocation();
  const prevLocation = usePrevLocation(location);

  return (
    <BreadCumbSwitch>
      <Route
        displayName="Home"
        path="/main"
        element={
          <PrivateRoute element={Dashboard} prevLocation={prevLocation} />
        }
      />
      <Route
        displayName="Graphics"
        path="/home"
        parentPath="/main"
        element={<PrivateRoute permissionName="home" element={Dashboard} />}
      />
      <Route
        displayName="Data Quality"
        path="/dataquality"
        parentPath="/main"
        element={
          <PrivateRoute permissionName="dataQuality" element={DataQuality} />
        }
      />
      <Route
        displayName="Missing Code Form"
        path="/missingCode"
        parentPath="/iv_missingcode"
        element={
          <PrivateRoute
            permissionName="missingCode"
            element={TransactionDetail}
          />
        }
      />
      <Route
        displayName="Missing Code"
        parentPath="/main"
        path="/iv_missingcode"
        element={
          <PrivateRoute permissionName="missingCode" element={IvMissingCode} />
        }
      />
      <Route
        displayName="Templates"
        path="/template"
        parentPath="/main"
        element={
          <PrivateRoute permissionName="template" element={TemplateList} />
        }
      />
      <Route
        displayName="Template Form"
        parentPath="/template"
        path="/template-form"
        element={
          <PrivateRoute permissionName="template" element={TemplateForm} />
        }
      />
      <Route
        displayName="All Transactions"
        path="/allTransaction"
        parentPath="/main"
        element={
          <PrivateRoute
            permissionName="transaction"
            element={AllTransactions}
          />
        }
      />

      <Route
        displayName="Air Call"
        path="/aircall-sms"
        parentPath="/main"
        element={<PrivateRoute permissionName="airCall" element={AirCallSms} />}
      />

      <Route
        displayName="Ring Central"
        path="/ring-central"
        parentPath="/main"
        element={
          <PrivateRoute permissionName="ringCentral" element={RingCentralSms} />
        }
      />

      <Route
        displayName="Transaction"
        path="/transaction-form"
        parentPath="/allTransaction"
        element={
          <PrivateRoute permissionName="transaction" element={MissingCode} />
        }
      />
      <Route
        displayName="Real Time"
        path="/insuranceverification"
        parentPath="/main"
        element={
          <PrivateRoute
            permissionName="realTime"
            element={Insuranceverification}
          />
        }
      />
      <Route
        displayName="Transactions"
        path="/grid-transaction"
        parentPath="/main"
        element={
          <PrivateRoute
            permissionName="transaction"
            element={TransactionForm}
          />
        }
      />
      <Route
        displayName="Transaction"
        path="/transaction"
        parentPath="/insuranceverification"
        element={
          <PrivateRoute permissionName="realTime" element={MissingCode} />
        }
      />
      <Route
        displayName="Users"
        path="/users"
        parentPath="/main"
        element={<PrivateRoute permissionName="user" element={Users} />}
      />
      <Route
        displayName="Client Practice"
        path="/client-practice"
        parentPath="/main"
        element={
          <PrivateRoute permissionName="user" element={ClientPractice} />
        }
      />
      <Route
        displayName="User Form"
        parentPath="/users"
        path="/user-form"
        element={<PrivateRoute permissionName="user" element={UserForm} />}
      />
      <Route
        displayName="File Converter"
        path="/converter"
        parentPath="/main"
        element={<FileConverter />}
      />
      <Route
        displayName="Profile"
        parentPath="/main"
        path="/profile"
        element={<PrivateRoute element={Profile} />}
      />
      <Route
        displayName="Login History"
        parentPath="/main"
        path="/login-history"
        element={<PrivateRoute element={LoginHistory} />}
      />
      <Route
        displayName="Connections"
        parentPath="/main"
        path="/connections"
        element={<PrivateRoute element={Connections} />}
      />

      <Route
        displayName="Flag Color"
        parentPath="/main"
        path="/colors"
        element={<PrivateRoute element={FlagColor} />}
      />
      <Route
        displayName="Billings"
        parentPath="/main"
        path="/billings"
        element={
          <PrivateRoute permissionName="billing_report" element={Billing} />
        }
      />

      <Route
        path="/air-call"
        element={<PrivateRoute permissionName="callCenter" element={AirCall} />}
      />

      <Route path="/air-call-detail/:callId" element={<AirCallDetail />} />
      <Route
        displayName="Command Center"
        parentPath="/main"
        path="/extraction_schedule"
        element={
          <PrivateRoute
            permissionName="extractionSchedule"
            element={ExtractionSchedule}
          />
        }
      />
      <Route
        displayName="Transaction Detail"
        path="/transaction-detail"
        parentPath="/allTransaction"
        element={
          <PrivateRoute
            permissionName="transaction"
            element={TransactionDetail}
          />
        }
      />
      <Route
        displayName="Transaction Detail"
        path="/transaction-detail-issue"
        parentPath="/allTransaction"
        element={
          <PrivateRoute
            permissionName="transaction"
            element={TransactionIssueDetail}
          />
        }
      />
      <Route
        displayName="Fee Schedule"
        parentPath="/main"
        path="/feeschedule"
        element={
          <PrivateRoute permissionName="feeSchedule" element={FeeSchedule} />
        }
      />
      <Route
        displayName="Transaction Plan"
        parentPath="/main"
        path="/transaction-plan"
        element={<TransactionPlan />}
      />
      <Route
        displayName="Transaction Plan Detail"
        parentPath="/transaction-plan"
        path="/transaction-plan-detail"
        element={<TransactionPlanDetail />}
      />
      <Route
        displayName="Fee Schedule Update"
        parentPath="/feeschedule"
        path="/feeschedule/update"
        element={
          <PrivateRoute permissionName="feeSchedule" element={UpdateRecords} />
        }
      />

      {/* Not in the menu */}
      <Route
        displayName="Credentials"
        parentPath="/main"
        path="/credentials"
        element={
          <PrivateRoute
            permissionName="clientCredentials"
            element={CredentialsList}
          />
        }
      />
      <Route
        displayName="Credentials Form"
        parentPath="/credentials"
        path="/credential-form"
        element={
          <PrivateRoute
            permissionName="clientCredentials"
            element={CredentialWrapped}
          />
        }
      />
      <Route
        displayName="Credentials Edit"
        parentPath="/credentials"
        path="/credential-edit"
        element={
          <PrivateRoute
            permissionName="clientCredentials"
            element={CredentialEdit}
          />
        }
      />
      <Route
        displayName="Logs"
        parentPath="/main"
        path="/logs"
        element={<ExtractionLog />}
      />
      <Route
        displayName="Clients NS"
        parentPath="/main"
        path="/client-ns"
        element={<PrivateRoute permissionName="clientsNS" element={ClientNS} />}
      />

      <Route
        displayName="DebtalMation Clients"
        parentPath="/main"
        path="/dentalmation-clients"
        element={
          <PrivateRoute
            permissionName="dentalMationClients"
            element={DentalMationClients}
          />
        }
      />

      <Route
        displayName="Product detail"
        parentPath="/dentalmation-clients"
        path="/dentalmation-product/:id"
        element={
          <PrivateRoute
            permissionName="dentalMationClients"
            element={DentalMationProduct}
          />
        }
      />

      <Route
        displayName="Claims"
        parentPath="/main"
        path="/claims"
        element={<PrivateRoute permissionName="claims" element={Claims} />}
      />

      <Route
        displayName="Audit Trait"
        parentPath="/main"
        path="/audit-trait"
        element={
          <PrivateRoute permissionName="auditTrait" element={AuditTrait} />
        }
      />

      <Route
        displayName="Shared Elements"
        parentPath="/main"
        path="/shared-elements"
        element={
          <PrivateRoute
            permissionName="sharedElements"
            element={SharedElements}
          />
        }
      />

      <Route
        displayName="Shared Elements"
        parentPath="/shared-elements/"
        path="/share-bookmarks/:id"
        element={
          <PrivateRoute
            permissionName="sharedElements"
            element={ShareBookmarks}
          />
        }
      />

      <Route
        displayName="Permissions"
        parentPath="/main"
        path="/permissions"
        element={
          <PrivateRoute permissionName="permissions" element={Permissions} />
        }
      />

      <Route
        displayName="Azure Files"
        parentPath="/main"
        path="/azure-files"
        element={
          <PrivateRoute permissionName="azureFiles" element={AzureFiles} />
        }
      />

      <Route
        displayName="Sftp Files"
        parentPath="/main"
        path="/sftp-files"
        element={
          <PrivateRoute
            //permissionName="sftpFiles"
            element={SftpFiles}
          />
        }
      />
      <Route
        displayName="Sftp Files Report"
        parentPath="/main"
        path="/sftp-files-report"
        element={
          <PrivateRoute
            //permissionName="sftpFiles"
            element={SftpFilesReport}
          />
        }
      />

      <Route
        displayName="Azure Files Report"
        parentPath="/azure-files"
        path="/azure-files-report"
        element={
          <PrivateRoute permissionName="azureFilesReport" element={Report} />
        }
      />
      <Route
        displayName="Summary Report"
        parentPath="/main"
        path="/summary-report"
        element={
          <PrivateRoute
            permissionName="summaryReport"
            element={SummaryReport}
          />
        }
      />

      <Route
        displayName="Client Cost Report"
        parentPath="/main"
        path="/client-cost-report"
        element={
          <PrivateRoute
            permissionName="clientCostReport"
            element={ClientCostReport}
          />
        }
      />

      <Route
        displayName="Transactions Columns"
        parentPath="/main"
        path="/columns-configurations"
        element={
          <PrivateRoute
            permissionName="columnsConfiguration"
            element={ColumnsConfiguration}
          />
        }
      />
      <Route
        displayName="Call Center"
        path="/callCenter"
        parentPath="/main"
        element={
          <PrivateRoute permissionName="callCenter" element={CallCenter} />
        }
      />

      <Route
        displayName="Intelepeer"
        parentPath="/main"
        path="/intelepeer"
        element={
          <PrivateRoute permissionName="intelepeer" element={Intelepeer} />
        }
      />

      <Route path="/clients/permissions" element={<ClientPermissions />} />

      <Route path="/dental-mation-clients" element={<DentalMationClients />} />
      <Route path="/user-request" element={<UserRequest />} />
      <Route path="/user-request-detail/:id" element={<UserRequestDetail />} />

      <Route path="/bookmarks" element={<Bookmarks />} />
      <Route path="/clients" element={<Clients />} />
      <Route path="/clients-form" element={<ClientsForm />} />
      <Route
        path="/reset_password_request"
        element={<ResetPasswordRequest />}
      />
      <Route path="/login" element={<Login />} />
      <Route path="/authorize/:trackLoginId" element={<Authorize />} />
      <Route path="/password_reset" element={<PasswordReset />} />
      <Route path="/password_create" element={<PasswordReset />} />
      <Route path="/" element={<Main />} />
      <Route path="/*" element={<Main />} />
      <Route path="/tree" element={<Tree />} />
      <Route
        path="/recall-transactions"
        element={<ReCallTransactions />}
        parentPath="/main"
        displayName="Recall - Transactions"
      />
    </BreadCumbSwitch>
  );
};

export default Routes;
