import React from "react";
import "../confirm-export-excel/styles/index.css";

const ConfirmExportExcel = ({
  isDownloadingFile = false,
  downloadGridData,
  setShowModalExport,
}) => {
  return (
    <div className="d-flex flex-column">
      <span style={{ fontWeight: 500, fontSize: 18, color: "#121212" }}>
        Confirm
      </span>
      <span style={{ fontWeight: 400, fontSize: 16, marginTop: 10 }}>
        Are you sure you want to download this file?
      </span>
      <div className="d-flex mt-4 justify-content-end" style={{ gap: 10 }}>
        <button
          className="cancel-download-button"
          onClick={() => setShowModalExport(false)}
          disabled={isDownloadingFile}
        >
          Cancel
        </button>
        <button
          className="confirm-download-button"
          disabled={isDownloadingFile}
          onClick={async () => {
            await downloadGridData();
          }}
        >
          {isDownloadingFile ? (
            <div
              className="d-flex align-items-center justify-content-center"
              style={{ gap: 5 }}
            >
              <span className="loader"></span>
              Downloading...
            </div>
          ) : (
            "Download"
          )}
        </button>
      </div>
    </div>
  );
};

export default ConfirmExportExcel;
