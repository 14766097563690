import { createRef, useCallback, useContext, useEffect, useState } from "react";
import { useSearchParams, useLocation } from "react-router-dom";
import shortid from "shortid";
import { AppContext } from "../../../App";
import { useAllTransactionsFilterNew } from "./components/AllTransactionsFilter/hooks";
import CustomTable from "../../CustomTable";
import { getRecords, handleDownloadExcelGridData } from "./services";
import { getUserColumnConfiguration } from "../ColumnsConfiguration/column-configuration.service";
import { createArrayOfColumnNames } from "../ColumnsConfiguration/column-configuration.middleware";
import { verifyFiles } from "../../../services/missingCode";
import { toast } from "react-toastify";
import moment from "moment";
import { manageDownloadDocument } from "../../../util/download-file";

const _setDateFilters = (filters) => {
  return {
    currentDate: filters.startDate ? new Date(filters.startDate) : new Date(),
    dateRange:
      filters.startDate && filters.endDate && filters.hasFilter
        ? {
            startDate: new Date(filters.startDate),
            endDate: new Date(filters.endDate),
          }
        : {},
  };
};

const baseColumns = [
  "Appointment",
  "Patient Name",
  "Practice",
  "Type",
  "Carrier Name",
  "Missing Fields",
  "Verification By",
];

const useAllTransactions = (permissions, selectedPractices, module_key) => {
  const modulesForReloadData = ["CC"];

  const _permissions = {
    missing_code: {
      edit_missingcode: false,
    },
    ...permissions,
  };
  const [, setSearchParams] = useSearchParams();

  const appContext = useContext(AppContext);
  const { transaction: sortTransaction } = appContext.get("sortConfiguration");
  const [showModalNew, setShowModalNew] = useState(false);
  const [filters, _setFilters] = useAllTransactionsFilterNew();
  const [loaded, setLoaded] = useState(false);
  const [dateFilters, setDateFilters] = useState(_setDateFilters(filters));
  const [key, setKey] = useState(shortid.generate());
  const [userColumns, setUserColumns] = useState([]);
  const [showModal, setShowModal] = useState(null);
  const [itemVerification, setItemVerification] = useState({});
  const [files, setFiles] = useState(null);
  const [action, setAction] = useState(false);
  const [showAnalytics, setShowAnalytics] = useState(false);
  const [showModalExport, setShowModalExport] = useState(false);
  const [isDownloadingFile, setIsDownloadingFile] = useState(false);
  const practices =
    JSON.parse(localStorage.getItem("appSettings")).practices || [];

  let location = useLocation();

  const ref = createRef(null);

  const setFilters = useCallback(
    (f) => {
      setShowAnalytics(false);
      const { form, range = false, ...rest } = f;
      if (form) {
        setDateFilters({
          ...dateFilters,
          currentDate: f.startDate || new Date(),
          dateRange: {
            startDate: f.startDate,
            endDate: f.endDate,
          },
        });
      }
      if (!range) {
        setDateFilters({
          ...dateFilters,
          currentDate: f.startDate || new Date(),
          dateRange: {},
        });
      }

      _setFilters(rest);
    },
    [filters],
  );

  useEffect(() => {
    if (
      showModal !== null &&
      !showModal &&
      modulesForReloadData.includes(module_key) &&
      action
    ) {
      window.location.reload();
    }
  }, [showModal]);

  const handleSetFilters = (f) => {
    setSearchParams({
      page: 0,
    });
    setDateFilters({
      ...dateFilters,
      currentDate: f.startDate || new Date(),
    });
    localStorage.setItem("filters", JSON.stringify(f));
    _setFilters(f);
  };

  const getData = useCallback(
    ({ page, pageSize }) => {
      function response(records = [], quantity = 0) {
        return CustomTable.createTableResponse(records, page, quantity);
      }

      if (selectedPractices.length === 0)
        return new Promise((resolve) => {
          resolve(response());
        });

      return getRecords(
        {
          ...filters,
          practiceId: selectedPractices,
          offset: page ? pageSize * page : 0,
        },
        pageSize,
      ).then(([status, r]) => {
        if (!status) {
          return response([], 0);
        }

        const formattedRecords =
          r && r.records.length > 0
            ? r.records.map((i) => {
                i.type = i.type.toUpperCase();
                return i;
              })
            : [];

        return response(formattedRecords, r.recordsQty || 0);
      });
    },
    [selectedPractices, filters, sortTransaction],
  );

  useEffect(() => {
    if (!loaded) {
      setLoaded(true);
    }
    if (ref.current != null && filters) {
      ref.current.onQueryChange();
    }
  }, [filters, selectedPractices]);

  const refresh = () => {
    if (ref.current != null && filters) {
      ref.current.onQueryChange();
    }
  };

  useEffect(() => {
    setKey(shortid.generate());
  }, [filters, sortTransaction]);

  useEffect(() => {
    async function userColumns() {
      const columns = await getUserColumnConfiguration("");
      if (columns.length > 0) {
        setUserColumns(createArrayOfColumnNames(columns));
      } else {
        setUserColumns(baseColumns);
      }
    }
    userColumns();
  }, [location]);

  async function handleVerify(id, practice) {
    const [status] = await verifyFiles(id, practice);

    if (status) {
      setFiles(id);
      setShowModal(true);
    }
  }

  function openModalVerification(item) {
    setItemVerification(item);
    setShowModal(true);
  }

  async function downloadGridData() {
    setIsDownloadingFile(true);
    const request = {
      ...filters,
      practiceId: selectedPractices,
      startDate: moment(filters.startDate, "YYYY-MM-DD").format("YYYY-MM-DD"),
      endDate: moment(filters.endDate, "YYYY-MM-DD").format("YYYY-MM-DD"),
      filter: cleanObject(filters.filter),
    };

    await handleDownloadExcelGridData(request)
      .then((response) => {
        downloadFile(response);
        setTimeout(() => {
          setIsDownloadingFile(false);
          setShowModalExport(false);
        }, 1000);
      })
      .catch(() => {
        showErrorAndHideLoadingFile();
      });
  }

  const showErrorAndHideLoadingFile = () => {
    toast.error("error downloading the file, try again");
    setIsDownloadingFile(false);
  };

  function downloadFile(response) {
    const practice =
      selectedPractices.length > 0
        ? practices.find((practice) => practice._id === selectedPractices[0])
        : "";

    manageDownloadDocument(
      `${practice.client[0].name || ""} - ${moment().format(
        "DD-MM-YYYY HH:mm:ss",
      )}`,
      response,
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    );
  }

  function cleanObject(obj) {
    const cleanedEntries = Object.entries(obj).filter(
      ([_, v]) => v.operation.trim() !== "" || v.value.trim() !== "",
    );

    return cleanedEntries.length > 0 ? Object.fromEntries(cleanedEntries) : {};
  }

  return {
    _permissions,
    sortTransaction,
    showModalNew,
    setShowModalNew,
    filters,
    _setFilters,
    loaded,
    setLoaded,
    dateFilters,
    setDateFilters,
    ref,
    setFilters,
    handleSetFilters,
    getData,
    refresh,
    key,
    userColumns,
    handleVerify,
    files,
    showModal,
    setShowModal,
    setFiles,
    openModalVerification,
    itemVerification,
    setAction,
    showAnalytics,
    setShowAnalytics,
    showModalExport,
    setShowModalExport,
    downloadGridData,
    isDownloadingFile,
  };
};

export default useAllTransactions;
