import Cookies from "universal-cookie";
import { decryptData, encryptData } from "../config/encryptData";

const cookies = new Cookies();
const AUTH_COOKIE = "authB";
const DATA_COOKIE = "otherOptions";

const localStorage = window.localStorage;
const LOCAL_SETTINGS_STORAGE = "appSettings";

const INITIAL_PATH = "/login";

let permissions = [];
let defaultPath = INITIAL_PATH;
let loggedInData = false;

let subscriberCount = 0;
let subscribers = [];

function _notifySubscribers() {
  for (let { fn } of subscribers) fn();
}

function _setAuthCookie(token) {
  cookies.set(AUTH_COOKIE, token, {
    path: "/",
    secure: true,
  });
}

function _setDataCookie(otherOptions) {
  cookies.set(DATA_COOKIE, JSON.stringify(otherOptions), {
    path: "/",
    secure: true,
  });
}
export function setLocalData(data) {
  localStorage.setItem(LOCAL_SETTINGS_STORAGE, JSON.stringify(data));
}

export function getLocalData() {
  const local = localStorage.getItem(LOCAL_SETTINGS_STORAGE);

  if (!local) {
    return {};
  }

  return JSON.parse(local);
}

export function getToken() {
  return cookies.get(AUTH_COOKIE);
}

export function setPermissions(p = []) {
  permissions = p;
  _notifySubscribers();
}

export function getPermissions(section = null) {
  if (!section) {
    return permissions;
  }
  const s = permissions.filter((x) => x.name === section);
  return s.length > 0 ? s[0] : false;
}

export function getActions(section = null) {
  const permissions = getPermissions(section);
  return permissions ? permissions["actions"] ?? [] : [];
}

export function getActionsAsObject(section = null) {
  const actions = getActions(section).reduce((a, i) => {
    return { ...a, [i.name]: true };
  }, {});
  return actions;
}

export function subscribe(fn) {
  subscribers.push({ index: subscriberCount, fn });
  return ++subscriberCount;
}

export function unsuscribe(index) {
  subscribers = subscribers.filter((x) => x.index !== index);
}

export function logout() {
  localStorage.clear();
  // Cleaning previous versions of this, just to prevent cookies from overflowing
  cookies.remove(LOCAL_SETTINGS_STORAGE, { path: "/" });
  cookies.remove(AUTH_COOKIE, { path: "/" });
  cookies.remove(DATA_COOKIE, { path: "/" });
  loggedInData = false;
  defaultPath = INITIAL_PATH;
  setPermissions([]);
}

export function getDefaultPath() {
  return defaultPath;
}

export function setDefaultPath(p) {
  defaultPath = p;
}

export function isLoggedIn() {
  return cookies.get(AUTH_COOKIE);
}

export function getLoggedInData() {
  return loggedInData;
}

export async function cacheLoggedInDataIfExists() {
  return new Promise(async (resolve, reject) => {
    const loggedIn = isLoggedIn();
    if (loggedIn && cookies.get(DATA_COOKIE)) {
      loggedInData = await decryptData(
        JSON.parse(cookies.get(DATA_COOKIE)),
      ).catch((e) => {
        return false;
      });
      resolve(true);
    }
    resolve(false);
  });
}

export async function login(token, otherOptions) {
  if (token && otherOptions) {
    _setAuthCookie(token);
    _setDataCookie(otherOptions);
    loggedInData = await decryptData(JSON.parse(cookies.get(DATA_COOKIE)));
  }
}

export function updateToken(token) {
  _setAuthCookie(token);
}

export async function updateLoggedInData(data) {
  loggedInData = { ...loggedInData, ...data };
  encryptData(loggedInData).then((r) => {
    _setDataCookie(JSON.stringify(r));
    _notifySubscribers();
    return true;
  });
}
