import React, { useEffect, useState } from "react";
import { Navigate } from "react-router";
import {
  faSort,
  faBars,
  faArrowDown,
} from "@fortawesome/fontawesome-free-solid";
import { withoutEmptyKeys } from "../../../util/object";
import { TemplateWrapped } from "../../Layout";
import { HeaderButton, HeaderFilterButton } from "../../RemarkTemplate/Body";
import AllTransactionsFilter from "./components/AllTransactionsFilter";
import SortForm from "./components/SortForm";
import TransactionModalForm from "../TransactionForm/TransactionModalForm";
import CustomModal from "../../CustomModal";
import useAllTransactions from "./useAllTransactions";
import withSelectedPractices from "../../HOCS/withSelectedPractices/withSelectedPractices";
import { Analitycs } from "./components/Analitycs";
import { operations } from "../../../util/operationsForFilters";
import useColumnFlag from "./components/ColumnGrid/ColumnFlag/hooks/useColumnFlag";
import TransactionTable from "../../TransactionTable";
import { transactionsColumnByModule } from "../../../util/transactionsColumnByModule";
import { transactionActionsColumnsByModule } from "../../../util/transactionActionsColumnsByModule";
import { transactionColorFlagColumn } from "../../../util/transactionColorFlagColumn";
import FilesList from "./components/FilesList";
import useAllTransactionsTable from "./components/useAllTransactionsTable";
import ConfirmExportExcel from "./components/confirm-export-excel/ConfirmExportExcel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const AllTransactions = ({
  actions = {
    list_transaction: true,
    audit_transaction: true,
    upload_elg: true,
    unaudit: false,
    updateElegibilityStatus: false,
  },
  permissions,
  ...props
}) => {
  const { selectedPractices } = props;
  const MODULE_KEY = "AT";
  const {
    _permissions,
    sortTransaction,
    showModalNew,
    setShowModalNew,
    filters,
    loaded,
    dateFilters,
    ref,
    setFilters,
    handleSetFilters,
    getData,
    refresh,
    key,
    userColumns,
    openModalVerification,
    showAnalytics,
    setShowAnalytics,
    showModalExport,
    setShowModalExport,
    downloadGridData,
    isDownloadingFile,
  } = useAllTransactions(permissions, selectedPractices, MODULE_KEY);

  const { handleIssueButtonClick } = useAllTransactionsTable(ref, "");

  const [filterOperation, setFilterOperation] = useState([]);
  const { flagColors, saveTransactionColor } = useColumnFlag(refresh);
  const { handleVerify, setFiles, setShowModal, files, showModal, history } =
    useAllTransactionsTable(ref, "practiceId");

  useEffect(() => {
    // TODO make API call
    setFilterOperation(operations);
  }, []);

  if (!actions.list_transaction) return <Navigate to="/" />;

  return (
    <TemplateWrapped
      title="Insurance Verification | All Transactions"
      headerButtons={
        <>
          <div className="d-flex">
            <div
              className="d-flex align-items-center justify-content-center pointer"
              style={{
                width: 30,
                height: 30,
                borderRadius: "50%",
                background: "white",
              }}
              onClick={() => setShowModalExport(true)}
            >
              <FontAwesomeIcon icon={faArrowDown} />
            </div>
            <HeaderButton
              icon={faBars}
              onClick={() => setShowAnalytics(true)}
              message="See analytics"
            />
            <HeaderFilterButton
              active={
                filters && Object.keys(withoutEmptyKeys(filters)).length > 0
              }
              render={AllTransactionsFilter}
              componentProps={{
                setFilters,
                initialData: filters,
                operations,
                module_key: MODULE_KEY,
              }}
            />
            <HeaderFilterButton
              icon={faSort}
              render={SortForm}
              message="Sort Configuration"
            />
          </div>
        </>
      }
    >
      <div className="panel">
        <div className="panel-body">
          <div className="row">
            <div className="col-md-12">
              {loaded && (
                <div className="all-transactions-table">
                  {showAnalytics && <Analitycs filters={filters} />}
                  <TransactionTable
                    key={key} // Force a re-render to avoid material's table bug
                    colorFlagColumn={transactionColorFlagColumn(
                      flagColors,
                      saveTransactionColor,
                      _permissions,
                      MODULE_KEY,
                    )}
                    columns={transactionsColumnByModule(MODULE_KEY)}
                    actionsColumns={transactionActionsColumnsByModule(
                      history,
                      actions.unaudit,
                      handleVerify,
                      ref,
                      MODULE_KEY,
                      openModalVerification,
                      handleIssueButtonClick,
                      "practiceId",
                      false,
                      permissions,
                    )}
                    tableReference={ref}
                    asyncDataSource={getData}
                    filters={filters}
                    currentDate={dateFilters.currentDate}
                    sortTransaction={sortTransaction}
                    range={dateFilters.dateRange}
                    handleSetFilters={handleSetFilters}
                    filterOperation={filterOperation}
                    visibleColumns={userColumns}
                    actions={actions}
                    module_key={MODULE_KEY}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <CustomModal
        show={showModalNew}
        onHide={() => setShowModalNew(false)}
        title="Create Transaction "
        size="lg"
      >
        <TransactionModalForm edit={false} setModal={setShowModalNew} />
      </CustomModal>
      {showModal && (
        <CustomModal
          size="xl"
          title="Files"
          onHide={() => {
            setShowModal(false);
            setFiles(null);
          }}
          show={showModal}
        >
          <FilesList
            id={files}
            practice={"practiceId"}
            canDownloadFiles={_permissions.files?.downloadFiles || false}
          />
        </CustomModal>
      )}

      <CustomModal
        show={showModalExport}
        onHide={() => setShowModalExport(false)}
        title="Confirmation"
        size="md"
        header={false}
      >
        <ConfirmExportExcel
          isDownloadingFile={isDownloadingFile}
          downloadGridData={downloadGridData}
          setShowModalExport={setShowModalExport}
        />
      </CustomModal>
    </TemplateWrapped>
  );
};

export default withSelectedPractices(AllTransactions);
