import { Api } from "../../../../config/Api";
import { statusAndResponse } from "../../../../util/requestHandler";

export const getAirCallMessages = async (queryParams) => {
  const onSuccess = (data) => {
    return data.data;
  };

  return statusAndResponse(
    Api.get(`/api/sms/v1/aircall/messages?${queryParams}`),
    onSuccess,
  );
};
