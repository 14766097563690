import { GridText } from "../AllTransactions/components/styles/AllTransactionTable.style";
import { getDateAsString } from "../../../util/date";
import React, { createRef, useCallback, useEffect, useState } from "react";
import CustomBadge from "../../CustomBadge/CustomBadge";
import { removeEmptyProperties } from "../../../util/remove-empty-key";
import { getAirCallMessages } from "./services";
import CustomTable from "../../CustomTable";
import moment from "moment";
import { capitalizeWords } from "../../../util/capitalize-words";
import { getVariantFromMessageStatus } from "../../../util/variants-from-message-status";

export const useAirCallSms = () => {
  const [filters, setFilters] = useState({
    phoneNumber: "",
    dateFrom: null,
    dateTo: null,
  });

  const ref = createRef(null);

  useEffect(() => {
    if (ref.current && ref.current.onQueryChange) {
      ref.current.onQueryChange();
    }
  }, [filters]);

  const getData = useCallback(
    async ({ page, pageSize }) => {
      const query = {
        ...filters,
        page: page + 1, // Ajuste si la API usa base 1
        perPage: pageSize,
        phoneNumber: filters.phoneNumber,
        dateFrom: filters.dateFrom
          ? `${moment(filters.dateFrom).format("YYYY-MM-DD")}T00:00:00.000Z`
          : "",
        dateTo: filters.dateTo
          ? `${moment(filters.dateTo).format("YYYY-MM-DD")}T23:59:59.000Z`
          : "",
      };

      const queryParamsFiltered = removeEmptyProperties(query);
      const queryParams = new URLSearchParams(queryParamsFiltered).toString();

      const response = async (records = [], quantity = 0) => {
        return CustomTable.createTableResponse(records, page, quantity);
      };

      try {
        let [status, r] = await getAirCallMessages(queryParams);
        if (!status) {
          return response([], 0);
        }

        return response(r.records, r.paging.totalElements || 0);
      } catch (error) {
        return response([], 0);
      }
    },
    [filters],
  );

  const columns = [
    {
      align: "left",
      field: "toPhoneNumber",
      render: ({ toPhoneNumber }) => {
        return <GridText>{toPhoneNumber}</GridText>;
      },
      sorting: false,
      title: "To",
    },
    {
      sorting: false,
      title: "From",
      field: "fromPhoneNumber",
      align: "left",
      render: ({ fromPhoneNumber }) => {
        return <GridText>{fromPhoneNumber}</GridText>;
      },
    },
    {
      sorting: false,
      title: "Text",
      field: "text",
      align: "left",
      render: ({ text }) => {
        return <GridText>{text}</GridText>;
      },
    },
    {
      sorting: false,
      title: "Message Type",
      field: "messageType",
      align: "center",
      render: ({ messageType }) => {
        return <GridText>{messageType}</GridText>;
      },
    },
    {
      sorting: false,
      title: "Event Date",
      field: "eventDate",
      align: "left",
      render: ({ eventDate }) => {
        return <GridText>{getDateAsString(eventDate, "yyyy-MM-DD")}</GridText>;
      },
    },
    {
      sorting: false,
      title: "Message Status",
      field: "messageStatus",
      align: "left",
      render: ({ messageStatus }) => {
        return (
          <div style={{ display: "flex" }}>
            <CustomBadge
              text={capitalizeWords(messageStatus)}
              variant={getVariantFromMessageStatus(messageStatus)}
            />
          </div>
        );
      },
    },
  ];

  return {
    columns,
    getData,
    filters,
    setFilters,
    ref,
  };
};
